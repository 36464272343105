<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">机构培训学员统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
            class="operationControl"
            style="display: flex; flex-direction: column; align-items: flex-start"
        >
          <div class="searchbox">
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>

              <el-cascader
                  v-model="userClassAreaId"
                  :options="areatreeList"
                  clearable
                  filterable
                  :props="propsarea"
                  size="small"
                  @change="(val)=>{clean(val,'userClassAreaId')}"
              ></el-cascader>
            </div>
            <div title="所属企业" class="searchboxItem ci-full">
              <span class="itemLabel">所属企业:</span>
              <el-select
                  size="small"
                  v-model="compId"
                  remote
                  :remote-method="getuserCompanyList"
                  filterable
                  clearable
                  placeholder="请至少输入两个字搜索"
                  style="width:100%"
              >
                <el-option
                    v-for="item in userCompanyList"
                    :key="item.compId"
                    :label="item.compName"
                    :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="学员入班时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 10em;">学员入班时间:</span>
              <el-date-picker
                  v-model="studentEntryTime"
                  type="datetimerange"
                  align="right"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  size="small"></el-date-picker>
            </div>
            <div class="df" style="padding-right: 15px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
              >
              <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="getExport()"
              >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <!-- <div class="ovy-a"> -->
          <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
          >
            <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
            />
<!--            <el-table-column-->
<!--                label="所属市"-->
<!--                align="left"-->
<!--                show-overflow-tooltip-->
<!--                prop="projectName"-->
<!--                min-width="100px"-->
<!--            />-->
            <el-table-column
                label="所属区域"
                align="left"
                show-overflow-tooltip
                prop="userClassAreaName"
                min-width="100px"
            />
            <el-table-column
                label="企业名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                min-width="150px"
            />
            <el-table-column
                label="注册人数"
                align="left"
                show-overflow-tooltip
                prop="registerNum"
                min-width="100px"
            />
<!--            <el-table-column-->
<!--                label="学习完成人数"-->
<!--                align="left"-->
<!--                show-overflow-tooltip-->
<!--                prop="projectCode"-->
<!--                min-width="100px"-->
<!--            />-->
            <el-table-column
                label="结业人数"
                align="left"
                show-overflow-tooltip
                prop="graduationNum"
                min-width="100px"
            />
            <el-table-column
                label="未实名人数"
                align="left"
                show-overflow-tooltip
                prop="notAuthenticationNum"
                min-width="100px"
            />

            <Empty slot="empty" />
          </el-table>
          <!-- </div> -->
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>

  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "ClassTrainingStatistics",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      areatreeList: [], // 行政区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      userClassAreaId: "",
      compId: "",
      studentEntryTime: "",
      userCompanyList: [],

      params: {},
      search: {
        userClassAreaId: true,
      }
    };
  },
  created() {},
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (oldVal.path != "/web/ClassTrainingStatisticsall") {
          (this.copNameState = false), (this.compName = "");
          this.userClassAreaId = "";
          this.getData(-1);
        } else {
          this.copNameState = true;
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.userClassAreaId = this.$route.query.areaId;
    this.getTableHeight();
    this.getareatree();
  },
  methods: {
    /* 所属企业 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
            .then(res => {
              if (res.status == 0) {
                this.userCompanyList = res.data || [];
              }
            })
            .catch(() => {
              return;
            });
      } else {
        this.userCompanyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        dateRange: this.dateRange,
        // endTime: this.endTime,
      };
      if (this.search.userClassAreaId) {
        this.userClassAreaId = this.$route.query.areaId;
      }
      if (this.userClassAreaId) {
        params.userClassAreaId = this.userClassAreaId;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if(this.studentEntryTime&&this.studentEntryTime.length>0){
        params.startTime = this.studentEntryTime[0];
        params.endTime = this.studentEntryTime[1];
      }
      this.doFetch({
        url: "/gov/project/compTrainUserStatistics",
        params,
        pageNum,
      });
    },
    getExport() {
      this.$confirm(
          "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
          "导出提示",
          {
            confirmButtonText: "导出",
            confirmButtonClass: "confirmButtonClass",
            cancelButtonText: "取消",
          }
      )
          .then(() => {
            const params = {};
            if (this.search.userClassAreaId) {
              this.userClassAreaId = this.$route.query.areaId;
            }
            if (this.userClassAreaId) {
              params.userClassAreaId = this.userClassAreaId;
            }
            if (this.compId) {
              params.compId = this.compId;
            }
            if(this.studentEntryTime&&this.studentEntryTime.length>0){
              params.startTime = this.studentEntryTime[0];
              params.endTime = this.studentEntryTime[1];
            }
            this.$post("/gov/project/compTrainUserStatisticsExport", params).then(
                (res) => {
                  if (res.status == "0") {
                    let list = res.data;

                    for (let item of list) {
                      console.log(item);
                      if (!this.downloadItems.includes(item.taskId)) {
                        this.$store.dispatch("pushDownloadItems", item.taskId);
                      } else {
                        this.$message.warning(
                            "[" + item.fileName + "]已经申请下载,请耐心等待"
                        );
                      }
                    }



                  } else {
                    this.$message.error(res.message);
                  }
                }
            );
          })
          .catch(() => {});
    },
    clean(val, name) {
      this.userClassAreaId = val;
      this.search[name] = false;
    },
    //获取行政区划
    getareatree() {
      this.$post("/gov/area/tree", { areaId: this.$route.query.areaId }).then(
          (ret) => {
            this.areatreeList = ret.data;
          }
      );
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 60;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },

  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
